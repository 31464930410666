body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.textSkills {
  font-size: 12px;
}
.color-jcit {
  color: #122b3b;
}
.style_ContratType {
  font-size: 18px;
}
.style_Company_supplier {
  font-size: 14px;
  color: black;
}
.p-30 {
  padding: 30px !important;
}
.card {
  border: 0;
  border-radius: 0px;
  margin-bottom: 30px;
  -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.03);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.03);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.avatar-xxl {
  width: 96px !important;
  height: 96px !important;
  line-height: 96px;
  font-size: 1.375rem;
}
.avatar {
  position: relative;
  display: inline-block;
  line-height: 36px;
  text-align: center;
  border-radius: 100%;
  background-color: #f5f6f7;
  color: #8b95a5;
  text-transform: uppercase;
}
.avatar.status-success::after {
  background-color: #15c377;
}

.avatar[class*="status-"]::after {
  content: "";
  position: absolute;
  right: 20px;
  bottom: 0;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  border: 2px solid #fff;
  background-color: #33cabb;
}
.avatar-xxl1 {
  width: 96px !important;
  height: 96px !important;
  line-height: 96px;
  font-size: 1.375rem;
}
.avatar1 {
  position: relative;
  display: inline-block;
  line-height: 36px;
  text-align: center;
  border-radius: 100%;
  background-color: #f5f6f7;
  color: #8b95a5;
  text-transform: uppercase;
}
.avatar1.status-failed::after {
  background-color: #ff0000;
}

.avatar1[class*="status-"]::after {
  content: "";
  position: absolute;
  right: 20px;
  bottom: 0;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  border: 2px solid #fff;
  background-color: #ff0000;
}
.pt-50 {
  padding-top: 50px !important;
}
h5,
.h5 {
  font-size: 16px;
  font-weight: 400;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: Roboto, sans-serif;
  font-weight: 300;
  color: #313944;
  line-height: 1.5;
  letter-spacing: 0.5px;
}
.avatar img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  vertical-align: top;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
.fs-13 {
  font-size: 13px !important;
}
.text-light {
  color: #616a78 !important;
}
.fs-12 {
  font-size: 12px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.gap-items > *,
.gap-items-3 > * {
  margin-left: 8px;
  margin-right: 8px;
}
.text_active {
  font-size: 10px;
  color: #33cabb;
  font-weight: bold;
}
.text_inactif {
  font-size: 10px;
  color: #e33545;
  font-weight: bold;
}
.user-card1-full {
  overflow: hidden;
}

.card11 {
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  border: none;
  margin-bottom: 30px;
}

.m-r-0 {
  margin-right: 0px;
}

.m-l-0 {
  margin-left: 0px;
}

.user-card1-full .user-profile {
  border-radius: 5px 0 0 5px;
}

.bg-c-lite-green {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#f29263),
    to(#ee5a6f)
  );
  background: linear-gradient(to right, #ee5a6f, #f29263);
}

.user-profile {
  padding: 20px 0;
}

.card1-block {
  padding: 1.25rem;
}

.m-b-25 {
  margin-bottom: 25px;
}

.img-radius {
  border-radius: 5px;
}

h6 {
  font-size: 14px;
}

.card1 .card1-block p {
  line-height: 25px;
}

@media only screen and (min-width: 1400px) {
  p {
    font-size: 14px;
  }
}

.card1-block {
  padding: 1.25rem;
}

.b-b-default {
  border-bottom: 1px solid #e0e0e0;
}

.m-b-20 {
  margin-bottom: 20px;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.card1 .card1-block p {
  line-height: 25px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.text-muted {
  color: #919aa3 !important;
}

.b-b-default {
  border-bottom: 1px solid #e0e0e0;
}

.f-w-600 {
  font-weight: 600;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-t-40 {
  margin-top: 20px;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-t-40 {
  margin-top: 20px;
}

.user-card1-full .social-link li {
  display: inline-block;
}

.user-card1-full .social-link li a {
  font-size: 20px;
  margin: 0 10px 0 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.button_steps_add {
  color: #7c7c7c;
}
.button_steps_dash {
  color: #c72424;
}
.button_steps_bgrdash {
  color: #fff;
  background-color: #c72424;
}
.button_steps_bgrdash:hover {
  color: #fff;
}
.border1 {
  border: 2px solid #5083e2 !important;
  border-radius: 13px;
}
.textcard_supplier {
  color: #05358c;
}
.border {
  border: 2px solid #5083e2 !important;
  border-radius: 13px;
}

.border_supplier_details {
  border: 2px solid #3f7ebd !important;
  background-color: #fff;
  border-radius: 13px;
}
.x-scroll {
  flex-wrap: nowrap;
  overflow-x: auto;
}
.border_column_steps {
  border-radius: 0px 0px 13px 13px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.border_column_steps_title {
  padding: 4px;
  height: auto;
  border-radius: 13px 13px 0px 0px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  min-height: 50px;
}
.shadow_btn {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.text_candidats {
  font-size: 13px;
}

.btn_delete {
  color: rgb(255, 0, 0);
  background-color: rgb(255, 255, 255);
  border: none;
}
.btn_delete:hover {
  color: rgb(255, 0, 0);
  background-color: rgb(255, 255, 255);
}
.size_length {
  font-size: 10px;
}
.color_candidat {
  color: black;
}
.manager-card {
  border: 2px solid #3f7ebd !important;
  background-color: #fff;
  border-radius: 13px;
}
