.timeline {
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.li {
  transition: all 200ms ease-in;
}

.timestamp {
  margin-bottom: 20px;
  padding: 0px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 100;
}

.status {
  padding: 0px 40px;
  display: flex;
  justify-content: center;
  border-top: 2px solid #d6dce0;
  position: relative;
  transition: all 200ms ease-in;
}
.status h4 {
  font-weight: 600;
}
.status:before {
  content: "";
  width: 25px;
  height: 25px;
  background-color: white;
  border-radius: 25px;
  border: 1px solid #ddd;
  position: absolute;
  top: -15px;
  left: 42%;
  transition: all 200ms ease-in;
}

.li.complete .status {
  border-top: 2px solid #0659e8;
}
.li.complete .status:before {
  background-color: #0659e8;
  border: none;
  transition: all 200ms ease-in;
}
.li.complete .status h4 {
  color: #66dc71;
}

@media (min-device-width: 320px) and (max-device-width: 700px) {
  .timeline {
    list-style-type: none;
    display: block;
  }
  .li {
    transition: all 200ms ease-in;
    display: flex;
    width: inherit;
  }
  .timestamp {
    width: 100px;
  }
  .status:before {
    left: -8%;
    top: 30%;
    transition: all 200ms ease-in;
  }
}

.add-new {
  top: -15px !important;
  width: 25px;
  height: 25px;
  background-color: white;
  border-radius: 25px;
  border: 1px solid #ddd;
  position: absolute;
  padding-left: 3px !important;
  padding-top: 0px !important;
  top: -15px;
  left: 42%;
  transition: all 200ms ease-in;
}
